import { coreGenerated } from './generated/core';


export const core = coreGenerated.enhanceEndpoints({});
// export const core = appCoreApi.enhanceEndpoints({
//   // these are the tags used to invalidate the cache for these endpoints
//   addTagTypes: ['Leader'],
//   endpoints: {
//     apiLatestAppCoreLeadersRetrieve: {
//       // these are the tags used to invalidate this specific endpoint
//       providesTags: ['Leader'],
//     },
//     apiLatestAppCoreLeadersPartialUpdate: {
//       // this causes invalidation of other caches which provide tags for Leader AND id
//       invalidatesTags: (result, err, arg) => [{ type: 'Leader', id: arg.id }],
//     },
//     // alternatively, define a function which is called with the endpoint definition as an argument
//     apiLatestAppCoreLeadersList(endpoint) {
//       endpoint.providesTags = ['Leader'];
//       endpoint.keepUnusedDataFor = 120;
//       endpoint.transformResponse = () => {
//         // transform response here
//         return { results: [] };
//       };
//     },
//   },
// });
